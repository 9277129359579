<template>
    <el-dialog v-model="visible" :title="editorForm.id ? '编辑' : '新增'" width="500px" :before-close="BeforeClose">
    <el-form :model="editorForm" :rules="rulesForm" ref="ruleFormRef" label-width="80px">
        <el-form-item label="卡片名称" prop="name">
            <el-input v-model="editorForm.name"></el-input>
        </el-form-item>
        <el-form-item label="图标" prop="icon">
            <Upload @success="UploadSuccess" ref="uploadRef"/>
        </el-form-item>
        <el-form-item label="缩略图" prop="shortIcon">
            <Upload @success="UploadShortIconSuccess" ref="uploadRef"/>
        </el-form-item>
        <el-form-item label="描述" prop="content">
            <el-input type="textarea" v-model="editorForm.content"></el-input>
        </el-form-item>
        <el-form-item label="类型" prop="type">
            <el-select v-model="editorForm.type">
                <el-option label="明信片" :value="1"></el-option>
                <el-option label="知识卡" :value="2"></el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="属性" prop="attribute">
            <el-select v-model="editorForm.attribute">
                <el-option label="普通" :value="1"></el-option>
                <el-option label="稀有" :value="2"></el-option>
            </el-select>
        </el-form-item>
    </el-form>

    <template #footer>
      <span class="dialog-footer">
        <el-button @click="visible = false">取消</el-button>
        <el-button type="primary" @click="EditorSubmit(ruleFormRef)">保存</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import { inject, nextTick, reactive, ref, watch } from 'vue'

import { CardAdd, CardEdit } from '@/api/card'
import Upload from '@/components/upload'


export default {
    components: {Upload},
    props: {
        title: {
            type: String,
            default: '新增商品'
        }
    },
    emits: ['submit'],
    setup(props, ctx) {
        const elplus = inject('elplus')
        const rulesForm = reactive({
            name: [{ required: true, message: '请填写名称', trigger: 'blur' }],
            icon: [{ required: true, message: '请上传图片', trigger: 'change' }],
        })
        let visible = inject('cardVisible')
        let editorForm = inject('editorForm')
        let ruleFormRef = ref(null)
        let uploadRef = ref(null)
        
        // 默认赋值
        watch(() => visible.value, () => {
            nextTick(() => {
                if (visible.value && uploadRef.value) {
                    uploadRef.value.SelectedImage(editorForm.value.icon)
                }
            })
        })
        
        const methods = {
            BeforeClose () {
                visible.value = false
            },
            EditorSubmit () {
                const formEl = ruleFormRef.value;
                if (!formEl) return
                formEl.validate((valid) => {
                    if (valid) {
                        let loading = elplus.loading()
                        if (editorForm.value.id) {
                            CardEdit({...editorForm.value}).then(res => {
                                loading.close()
                                if (!res.ret) ctx.emit('submit')
                            })
                        } else {
                            CardAdd({...editorForm.value, }).then(res => {
                                loading.close()
                                if (!res.ret) ctx.emit('submit')
                            })
                        }
                    }
                })
            },
            UploadSuccess (res) {
                editorForm.value.icon = res.data
            },
            UploadShortIconSuccess (res) {
                editorForm.value.shortIcon = res.data
            }
        }

        return {
            uploadRef,
            ruleFormRef,
            rulesForm,
            visible,
            editorForm,
            ...methods
        }
    },
}
</script>

<style lang="scss" scoped>

</style>