import axios from "@/utils/axios";

export const list = 'card/list'

export const CardAdd = (data) => {
    return axios.post(`card/add`, data)
}

export const CardEdit = (data) => {
    return axios.post(`card/edit`, data)
}

export const CardDel = (data) => {
    return axios.post(`card/del`, data)
}

